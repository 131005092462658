<template>
  <div class="user-center-item">
    <div class="user-center-item-nav">
      <el-button @click="jump2UserInfo">修改账户信息</el-button>
    </div>
    <div class="user-center-item-content">
      <el-form>
        <el-form-item label="姓名">{{ userInfos.displayName }}</el-form-item>
        <el-form-item label="用户名">{{ userInfos.userName }}</el-form-item>
        <el-form-item label="行政区划">{{ userInfos.areaName }}</el-form-item>
        <el-form-item label="联系电话">{{ userInfos.phoneNumber }}</el-form-item>
        <el-form-item label="邮箱">{{ userInfos.primaryMail }}</el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUserProfile, getUserInfo } from '@/api/auth';
import { getAreaName } from '@/api/cim';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'SpaceUserInfo',
  setup() {
    const userInfos = ref({});
    getUserProfile().then((res) => {
      userInfos.value = { ...userInfos.value, ...res.data };
    });
    getUserInfo()
      .then((res) => {
        userInfos.value.userId = res?.data?.info?.userId;
        return getAreaName(userInfos.value.userId);
      })
      .then((res) => {
        userInfos.value.areaName = res?.data?.areaName || '暂无';
      });

    const router = useRouter();
    const jump2UserInfo = () => {
      router.push('/user-management/user-detail');
    };
    return {
      userInfos,
      jump2UserInfo,
    };
  },
};
</script>

<style lang="scss">
.user-center-item {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  &-nav {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  &-content {
    padding: 20px;
    .el-form-item__label {
      display: inline-block;
      width: 100px;
      text-align: left;
    }
  }
}
</style>
